<template>
  <b-card>
    <FormView
        :data="$store.getters['brand/detailData']"
        :fields="$store.getters['brand/tableFields']"
        @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('brand/getDetail', id)
    },
  },
}
</script>

<style scoped>

</style>
